import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import MainContentsQuestionnairePageOption from "../MainContentsQuestionnairePageOption";
import {
  getRevisedQuestionWithAnswers,
  getRiverFunctionSummary,
} from "../Utilities/Util.js";
import QuestionsListData from "../Data/QuestionsData.json";
import RiverFunctionWithQuestionArray from "../Data/RiverFunctionWithQuestionArray.json";
import RiverFunctionCategoryWithQuestionArray from "../Data/RiverFunctionCategoryWithQuestionArray.json";
import Modal from "react-modal";
import RiverFunctionDetail from "../RiverFunctionDetail.js";
import { FcNext, FcPrevious } from "react-icons/fc";
import { GrSettingsOption } from "react-icons/gr";
import { BiSortDown, BiReset } from "react-icons/bi";
import "../../config.js";
import { barChartBlue, barChartGreen } from "../colors.js";
import PageTitle from "../PageTitle.js";
import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

const PrioritizationStyled = styled.div`
  .threshold-adjuster {
    margin-left: 0.75rem;
  }
`;

const WarningButtonStyled = styled.button`
  align-self: center;
  padding: 4px 16px;
  height: 34px;
  border: 2px solid ${(props) => props.theme.color.warning};
  border-radius: 8px;
  background-color: #fff;
  color: ${(props) => props.theme.color.warning};
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.color.warning};
    color: #fff;
  }
`;

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",

    height: "80%",

    borderRadius: "4px",
    border: "1px solid black",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",

    transform: "translate(-50%, -50%)",
  },
};

const customStylesForPrioritizationError = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",

    height: "300px",
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid black",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",

    transform: "translate(-50%, -50%)",
  },
};

const sortRiverFunction = (riverFunctionList, topicCategory) => {
  // sort by value
  riverFunctionList.sort(function (a, b) {
    if (topicCategory === global.config.prioritization.KeyTopicCategory)
      return b.yes_percentage - a.yes_percentage;
    else return b.unc_percentage - a.unc_percentage;
  });

  return riverFunctionList;
};

const columnsFromBackend = {
  RFIs: {
    name: "RFIs",
    description:
      "List of preliminary RFIs is based on ≥ 50% responses of “Yes”",
    items: [],
  },
  Retain: {
    name: "Retain",
    description: "Key topics that you want to retain",
    items: [],
  },
  Discard: {
    name: "Discard",
    description: "Key topics that you want to discard",
    items: [],
  },
};

// let priorityAnsweredRiverFunctions = riverFunctionSummary.filter((rf) => {
//     return rf.yes_percentage >= global.config.prioritization.min_yes_percentage
// })

const setColumnNameAndDescription = (newTopicCategory, col) => {
  if (newTopicCategory === global.config.prioritization.KeyTopicCategory) {
    col.RFIs.name = "RFIs with Key Topics";
    col.RFIs.description =
      "List of preliminary RFIs is based on ≥ " +
      parseInt(global.config.prioritization.min_yes_percentage * 100) +
      "% responses of “Yes”";

    col.Retain.description = "Key topics that you want to retain";
    col.Discard.description = "Key topics that you want to discard";
  } else {
    col.RFIs.name = "RFIs with Uncertain Topics";
    col.RFIs.description =
      "List of preliminary RFIs is based on ≥ " +
      parseInt(global.config.prioritization.min_unc_percentage * 100) +
      '% responses of "Uncertain"';
    col.Retain.description = "Uncertain topics that you want to retain";
    col.Discard.description = "Uncertain topics that you want to discard";
  }

  return col;
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }

  if (source.droppableId !== destination.droppableId) {
    localStorage.setItem(
      global.config.prioritization.local_storage_prioritization_timestamp_name,
      new Date().toLocaleString()
    );
  }
};

// ________________________________________________________________________________________________________________________________________
const Prioritization = () => {
  // Use state to track the minYesPercentage
  const [minYesPercentage, setMinYesPercentage] = useState(
    global.config.prioritization.min_yes_percentage
  );
  const [prioritizationThreshold, setPrioritizationThreshold] = useState(
    global.config.prioritization.min_yes_percentage * 100
  );

  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);
  const riverFunctionSummary = getRiverFunctionSummary(
    RiverFunctionWithQuestionArray,
    answerJson
  );

  const notAnsweredRiverFunctions = riverFunctionSummary.filter((rf) => {
    return rf.not_answered_count > 0;
  });

  const getInitialRiverFunctionIndicators = useCallback(
    (topicCategoryName) => {
      let keyTopicRiverFunctions = [];

      if (topicCategoryName === global.config.prioritization.KeyTopicCategory) {
        keyTopicRiverFunctions = riverFunctionSummary.filter((rf) => {
          return rf.yes_percentage >= minYesPercentage;
        });
      } else {
        keyTopicRiverFunctions = riverFunctionSummary.filter((rf) => {
          return (
            rf.unc_percentage >= global.config.prioritization.min_unc_percentage
          );
        });
      }

      keyTopicRiverFunctions.forEach((rf) => {
        rf.id = rf.river_function_code;
      });

      let columns = { ...columnsFromBackend };
      columns.RFIs.items = sortRiverFunction(
        keyTopicRiverFunctions,
        topicCategoryName
      );
      columns = setColumnNameAndDescription(topicCategoryName, columns);

      localStorage.setItem(
        "Prioritization_" + topicCategoryName,
        JSON.stringify(columns)
      );

      return columns;
    },
    [minYesPercentage, riverFunctionSummary]
  );

  const getRiverFunctionPrioritization = (topicCategoryName) => {
    const priorityTopicCategoryText = localStorage.getItem(
      "Prioritization_" + topicCategoryName
    );

    let newColumns = null;

    let empty =
      '{"RFIs":{"name":"RFIs with Key Topics","description":"List of preliminary RFIs is based on ≥ 50% responses of “Yes”","items":[]},"Retain":{"name":"Retain","description":"Key topics that you want to retain","items":[]},"Discard":{"name":"Discard","description":"Key topics that you want to discard","items":[]}}';

    if (
      priorityTopicCategoryText !== null &&
      priorityTopicCategoryText !== "" &&
      priorityTopicCategoryText !== "null" &&
      priorityTopicCategoryText !== empty
    ) {
      try {
        newColumns = JSON.parse(priorityTopicCategoryText);
        newColumns = setColumnNameAndDescription(topicCategoryName, newColumns);
      } catch (e) {
        console.log("error:", e);
      }
    } else {
      newColumns = getInitialRiverFunctionIndicators(topicCategoryName);
    }

    return newColumns;
  };

  const [topicCategory, setTopicCategory] = useState(
    global.config.prioritization.KeyTopicCategory
  );
  const [columns, setColumns] = useState(
    getRiverFunctionPrioritization(topicCategory)
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [riverFunctionCode, setRiverFunctionCode] = useState("");
  const [modalIsPrioritizationError, setModalIsPrioritizationError] =
    useState(false);

  document.title =
    global.config.title +
    ": Prioritization - " +
    (topicCategory === global.config.prioritization.KeyTopicCategory
      ? "Key Topics"
      : "Uncertain Topics");

  useEffect(() => {
    const Prioritization_TimeStamp = Date.parse(
      localStorage.getItem(
        global.config.prioritization.local_storage_prioritization_timestamp_name
      )
    );
    const LatestAnswer_TimeStamp = Date.parse(
      localStorage.getItem(
        global.config.questionnaire.local_storage_last_answer_timestamp_name
      )
    );

    if (LatestAnswer_TimeStamp > Prioritization_TimeStamp) {
      setModalIsPrioritizationError(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "Prioritization_" + topicCategory,
      JSON.stringify(columns)
    );
  }, [topicCategory, columns]);

  // Trigger refetching when the topic category or the threshold changes
  useEffect(() => {
    global.config.prioritization.min_yes_percentage = minYesPercentage;
    global.config.prioritization.min_unc_percentage = minYesPercentage;
    console.log("minYesPercentage updated:", minYesPercentage);

    const update = () => {
      const updatedColumns = getInitialRiverFunctionIndicators(topicCategory);
      console.log("updatedColumns:", updatedColumns);

      // Only update the state if columns have changed
      setColumns((prevColumns) => ({
        ...prevColumns,
        ...updatedColumns,
        Discard: prevColumns?.Discard || {},
        Retain: prevColumns?.Retain || {},
      }));
    };

    update();
  }, [minYesPercentage, topicCategory, getInitialRiverFunctionIndicators]);

  const clearPrioritization = () => {
    localStorage.removeItem(
      global.config.prioritization.local_storage_prioritization_timestamp_name
    );
    localStorage.removeItem(
      "Prioritization_" + global.config.prioritization.KeyTopicCategory
    );
    localStorage.removeItem(
      "Prioritization_" + global.config.prioritization.UncertainTopicCategory
    );

    const initialColumns = getInitialRiverFunctionIndicators(topicCategory);
    setColumns(initialColumns);
    setModalIsPrioritizationError(false);
  };

  const sortRiverFunctionHandler = (e) => {
    const cols = columns;
    cols.RFIs.items = sortRiverFunction(cols.RFIs.items, topicCategory);
    cols.Retain.items = sortRiverFunction(cols.Retain.items, topicCategory);
    cols.Discard.items = sortRiverFunction(cols.Discard.items, topicCategory);
    setColumns(cols);
  };

  Modal.setAppElement("#root");

  function openModal(rfCode) {
    setRiverFunctionCode(rfCode);
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setModalIsOpen(false);
    setModalIsPrioritizationError(false);
  }

  const moveToTopicCategory = (newTopicCategory) => {
    setTopicCategory(newTopicCategory);
    const columnsForThisCategory =
      getRiverFunctionPrioritization(newTopicCategory);
    setColumns(columnsForThisCategory);
  };

  const changePrioritizationThreshold = (option) => {
    const newThreshold = option / 100;
    setPrioritizationThreshold(option);
    setMinYesPercentage(newThreshold); // Update state with the new threshold
  };

  return (
    <PrioritizationStyled className='main-content'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='content'>
              {topicCategory ===
                global.config.prioritization.KeyTopicCategory && (
                <>
                  <PageTitle title='Prioritization Part 1: Key Topics' />
                  <p>
                    A list of river functions that may represent{" "}
                    <span style={{ color: barChartBlue, fontWeight: "bold" }}>
                      key topics
                    </span>{" "}
                    for this project can be found below on the left. This
                    preliminary list was generated based on the percentage of
                    "yes" responses for each river function. Based on your
                    understanding of the project, please review this list of
                    river functions and determine if you would "retain" or
                    "discard" them as key topics for this project{" "}
                    <u>
                      by dragging and dropping them into the appropriate bin on
                      the right
                    </u>
                    .
                  </p>
                </>
              )}
              {topicCategory ===
                global.config.prioritization.UncertainTopicCategory && (
                <>
                  <PageTitle title='Prioritization Part 2: Uncertain Topics' />
                  <p>
                    A list of river functions that may represent{" "}
                    <span style={{ color: barChartGreen, fontWeight: "bold" }}>
                      uncertain topics
                    </span>{" "}
                    for this project (i.e. topics that require additional
                    information) can be found below on the left. This
                    preliminary list was generated based on the percentage of
                    "uncertain" responses for each river function. Based on your
                    understanding of this project, please review this list of
                    river functions and determine if you would "retain" or
                    "discard" them as uncertain topics for this project{" "}
                    <u>
                      by dragging and dropping them into the appropriate bin on
                      the right
                    </u>
                    .
                  </p>
                </>
              )}
              {notAnsweredRiverFunctions.length > 0 && (
                <div
                  style={{
                    border: "1px solid red",
                    backgroundColor: "yellow",
                    color: "red",
                    padding: "20px",
                    marginBottom: "5px",
                    borderRadius: "0.5rem",
                  }}
                >
                  Not All Questions were answered.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <b>Adjust Prioritization Threshold:</b>
                  <select
                    className='threshold-adjuster'
                    value={prioritizationThreshold}
                    onChange={(e) =>
                      changePrioritizationThreshold(e.target.value)
                    }
                  >
                    <option value='10'>10%</option>
                    <option value='20'>20%</option>
                    <option value='30'>30%</option>
                    <option value='40'>40%</option>
                    <option value='50'>50%</option>
                    <option value='60'>60%</option>
                    <option value='70'>70%</option>
                    <option value='80'>80%</option>
                    <option value='90'>90%</option>
                    <option value='100'>100%</option>
                  </select>
                  <div style={{ fontSize: "smaller", fontStyle: "italic" }}>
                    Adjusting this value will filter your list of preliminary
                    RFIs based on the selected minimum threshold.
                  </div>
                </div>

                {topicCategory ===
                  global.config.prioritization.KeyTopicCategory && (
                  <button
                    type='button'
                    className='btn btn-outline-success'
                    onClick={() =>
                      moveToTopicCategory(
                        global.config.prioritization.UncertainTopicCategory
                      )
                    }
                  >
                    Go to the List of RFIs with Uncertain Topics <FcNext />
                  </button>
                )}
                {topicCategory ===
                  global.config.prioritization.UncertainTopicCategory && (
                  <button
                    type='button'
                    className='btn btn-outline-primary'
                    onClick={() =>
                      moveToTopicCategory(
                        global.config.prioritization.KeyTopicCategory
                      )
                    }
                  >
                    <FcPrevious /> Go to the List of RFIs with Key Topics
                  </button>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  marginTop: "20px",
                }}
              >
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "33%",
                        }}
                        key={index}
                      >
                        <div style={{ margin: 7, width: "90%" }}>
                          <>
                            <h5>{column.name}</h5>
                            <div
                              style={{
                                height: "60px",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "0.9rem",
                              }}
                            >
                              {column.description}
                            </div>
                            <Droppable droppableId={columnId} key={columnId}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      border: "1px dotted black",
                                      background: snapshot.isDraggingOver
                                        ? "lightcyan"
                                        : "rgb(240, 240, 240)",
                                      padding: 4,
                                      width: "100%",
                                      minHeight: 500,
                                    }}
                                  >
                                    {column.items.map((item, index) => {
                                      return (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='list-group-item d-flex justify-content-between align-items-center'
                                                title={
                                                  item.river_function_desc_txt
                                                }
                                                style={{
                                                  userSelect: "none",
                                                  padding: 5,
                                                  marginBottom: "2px",
                                                  fontSize: ".8rem",
                                                  borderRadius: "0.5rem",
                                                  verticalAlign: "middle",
                                                  // backgroundColor: snapshot.isDragging
                                                  //   ? "#263B4A"
                                                  //   : item.bgColor,
                                                  backgroundColor: item.bgColor,
                                                  color: "white",
                                                  ...provided.draggableProps
                                                    .style,
                                                }}
                                                onClick={(e) =>
                                                  openModal(
                                                    item.river_function_code
                                                  )
                                                }
                                              >
                                                {item.river_function_code}:{" "}
                                                {item.river_function_name}
                                                <span
                                                  className='badge bg-light  text-dark rounded-pill'
                                                  title={
                                                    topicCategory ===
                                                    global.config.prioritization
                                                      .KeyTopicCategory
                                                      ? "Yes percentage"
                                                      : "Uncertain percentage"
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {topicCategory ===
                                                    global.config.prioritization
                                                      .KeyTopicCategory &&
                                                    parseInt(
                                                      item.yes_percentage * 100
                                                    )}
                                                  {topicCategory ===
                                                    global.config.prioritization
                                                      .UncertainTopicCategory &&
                                                    parseInt(
                                                      item.unc_percentage * 100
                                                    )}
                                                  %
                                                </span>
                                              </div>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
            </div>
            {/*  End of Content */}
          </div>
          <div className='col-xl-2 col-lg-0 col-md-12 col-sm-12 col-12'>
            <div className='sticky-top'>
              {/* Page Option in Canvas for River Function Chart */}
              <div
                style={{ marginTop: "20px", width: "150px" }}
                title='Page Display options'
                className='no-print'
              >
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasOptions_prioritization'
                  aria-controls='offcanvasOptions_prioritization'
                  style={{ width: "130px" }}
                >
                  <GrSettingsOption /> Page Option
                </button>
              </div>
              <MainContentsQuestionnairePageOption />

              <div
                className='offcanvas offcanvas-end'
                tabIndex='-1'
                id='offcanvasOptions_prioritization'
                aria-labelledby='offcanvasOptionLabel'
              >
                <div className='offcanvas-header'>
                  <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
                    <GrSettingsOption /> Options
                  </h5>
                  <button
                    type='button'
                    className='btn-close text-reset'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                  />
                </div>

                <div className='offcanvas-body' style={{ display: "none" }}>
                  <div className='card'>
                    <div className='card-header'>
                      <BiSortDown />
                      Sort Options
                    </div>
                    <div className='card-body small'>
                      <h5 className='card-title'>Sort River Functions</h5>
                      <div>
                        <button
                          type='button'
                          className='btn btn-secondary btn-sm'
                          onClick={(e) => sortRiverFunctionHandler(e)}
                        >
                          Sort by Percentage
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='offcanvas-body'>
                  <div className='card'>
                    <div className='card-header'>
                      <BiReset />
                      Prioritization Options
                    </div>
                    <div className='card-body small'>
                      <div>
                        <WarningButtonStyled
                          type='button'
                          className='btn btn-secondary btn-sm'
                          onClick={(e) => clearPrioritization(e)}
                        >
                          <BiReset />
                          Reset Prioritization
                        </WarningButtonStyled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ marginTop: "20px", width: "130px" }}
                title='View Legend'
                className='no-print'
              >
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasOptions_legend'
                  aria-controls='offcanvasOptions_legend'
                  style={{ width: "130px" }}
                >
                  <GrSettingsOption /> Legend
                </button>
                <div
                  className='offcanvas offcanvas-end legend'
                  tabIndex='-1'
                  id='offcanvasOptions_legend'
                  aria-labelledby='offcanvasOptionLabel'
                >
                  <div className='offcanvas-header'>
                    <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
                      Legend
                    </h5>
                    <button
                      type='button'
                      className='btn-close text-reset'
                      data-bs-dismiss='offcanvas'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='card-body'>
                    {RiverFunctionCategoryWithQuestionArray.map(
                      (rfc, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: rfc.bgColor,
                            margin: "5px",
                            padding: "5px",
                            color: "white",
                            fontSize: ".8rem",
                            borderRadius: "0.5rem",
                          }}
                          title={rfc.definition_txt}
                        >
                          {rfc.river_function_category_code} :{" "}
                          {rfc.river_function_category_name}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* End of Card */}
            </div>
          </div>

          {/* End of Row */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
          >
            <RiverFunctionDetail riverFunctionCode={riverFunctionCode} />

            <div style={{ textAlign: "center" }}>
              <button
                onClick={() => closeModal()}
                className='btn btn-primary btn-sm'
              >
                Close
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsPrioritizationError}
            onRequestClose={closeModal}
            style={customStylesForPrioritizationError}
            onClick={() => setModalIsPrioritizationError(false)}
            contentLabel='Warning'
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#177AC8",
                color: "white",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              Warning!
            </div>
            <p>
              Since you last worked on Prioritization on{" "}
              {localStorage.getItem(
                global.config.prioritization
                  .local_storage_prioritization_timestamp_name
              )}
              , you changed the answer(s) to the questionnaire. Please restart
              your prioritization process based on your latest answers.
            </p>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <WarningButtonStyled onClick={() => clearPrioritization()}>
                Reset Prioritizations
              </WarningButtonStyled>
            </div>
          </Modal>
        </div>

        {/* End of Container */}
      </div>
      <SequentialPageNav prev='/Impacts/Results' next='/FinalReportDetail' />
      {/* End of Main-Content */}
    </PrioritizationStyled>
  );
};

export default Prioritization;
